<template>
  <div class="myself">
      	<t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header> 
      	<div class="myselfdiv">
			<div class="myself_breadcrumb">
				<i class="el-icon-s-home"></i>
				<p>当前位置 ： {{showTitle}}</p>
			</div>
        <div class="myself_comtent">
            <div class="myself_comtent_nav">
                <div class="myself_comtent_nav_msg">
                    <img :src="userInfo.headPic">
                    <p>{{userInfo.nickName}}</p>
                    <p>{{userInfo.mobile}}</p>
                    <!-- <el-button type="primary" plain @click="topage('/myself')">基本信息</el-button> -->
                </div>
                <!-- <div class="myself_comtent_nav_list"> -->
                    <el-col >
                      	<el-menu unique-opened	 default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :default-openeds='arr'>
							<el-menu-item index="1" @click="topage('/myself/myCenter')">
								<i class="el-icon-notebook-2"></i>
								<span slot="title">学习概况</span>
							</el-menu-item>
							<el-submenu index="2">
								<template slot="title">
									<i class="el-icon-monitor"></i>
									<span>学习中心</span>
								</template>
								<el-menu-item-group>
									<!-- <template slot="title">分组一</template> -->
									<el-menu-item index="1-1" class="el-menu-item_li" @click="topage('/myself/myClass')">我的课程</el-menu-item>
									<el-menu-item index="1-2" class="el-menu-item_li" @click="topage('/myself/myLive')">我的直播</el-menu-item>
									<!-- <el-menu-item index="1-3" class="el-menu-item_li" @click="topage('/myself/myClassAnswer')">课程答疑</el-menu-item> -->
									<el-menu-item index="1-4" class="el-menu-item_li" @click="topage('/myself/myNote')">课程笔记</el-menu-item>
									<el-menu-item index="1-5" class="el-menu-item_li" @click="topage('/myself/myDaYi')">课程答疑</el-menu-item>
									<!-- <el-menu-item index="1-5" class="el-menu-item_li">考试题库</el-menu-item> -->
								</el-menu-item-group>
							</el-submenu>
							<!-- <el-menu-item index="3" @click="topage('/myself/myAnswer')">
								<i class="el-icon-connection"></i>
								<span slot="title">我的问答</span>
							</el-menu-item> -->
								
							<el-submenu index="4">
								<template slot="title">
									<i class="el-icon-money"></i>
									<span>我的资产</span>
								</template>
								<el-menu-item-group>
									<!-- <template slot="title">分组一</template> -->
									<el-menu-item index="1-1" class="el-menu-item_li" @click="topage('/myself/myOrder')">订单</el-menu-item>
									<el-menu-item index="1-2" class="el-menu-item_li" @click="topage('/myself/myIntegral')">积分</el-menu-item>
									<el-menu-item index="1-3" class="el-menu-item_li" @click="topage('/myself/myCoupon')">优惠券</el-menu-item>
									<el-menu-item index="1-4" class="el-menu-item_li" @click="topage('/myself/myClassExchange')">课程兑换</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="5">
								<template slot="title">
									<i class="el-icon-user"></i>
									<span>账号设置</span>
								</template>
								<el-menu-item-group>
									<!-- <template slot="title">分组一</template> -->
									<el-menu-item index="1-1" class="el-menu-item_li" @click="topage('/myself/editUserInfo')">个人资料</el-menu-item>
									<el-menu-item index="1-2" class="el-menu-item_li" @click="topage('/myself/myInformation')">站内消息</el-menu-item>
									<el-menu-item index="1-3" class="el-menu-item_li" @click="signOut()">退出</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<!-- <el-menu-item index="3">
								<i class="el-icon-menu"></i>
								<span slot="title">导航二</span>
							</el-menu-item>
							<el-menu-item index="4" disabled>
								<i class="el-icon-document"></i>
								<span slot="title">导航三</span>
							</el-menu-item>
							<el-menu-item index="5">
								<i class="el-icon-setting"></i>
								<span slot="title">导航四</span>
							</el-menu-item> -->
                      	</el-menu>
                    </el-col>
                    <!-- <p @click="topage('/myself/mycourse')" >
                      <span :class="path=='/myself/mycourse'?'leftIcon':''"></span>
                      <span :class="path=='/myself/mycourse'?'backcolor':'noneclock'">我的课程</span>
                    </p> -->
                <!-- </div> -->
            </div>
            <div class="myself_comtent_show">
                <router-view/>
            </div>
        </div>
      </div>
      <t_footer></t_footer> 
  </div>
</template>

<script>
import t_header from "@/components/t_header/index.vue";
import t_footer from "@/components/t_footer/index.vue";
import { get_user_info } from "@/api/myself";
import { get_video_token } from "@/api/exam";
const oTit = {
	"/myself/myCenter":'学习概况',
	"/myself/myClass":'学习中心 > 我的课程',
	"/myself/myLive":'学习中心 > 我的直播',
	"/myself/myNote":'学习中心 > 我的笔记',
	"/myself/myDaYi":'学习中心 > 我的答疑',
	"/myself/myAnswer":'我的问答',
	"/myself/myOrder":'我的资产 > 订单',
	"/myself/myIntegral":'我的资产 > 积分',
	"/myself/myCoupon":'我的资产 > 优惠券',
	"/myself/myClassExchange":'我的资产 > 课程兑换',
	"/myself/editUserInfo":'账号设置 > 个人资料',
	"/myself/myInformation":'账号设置 > 站内消息',
}
export default {
	name: "myself",
	components: {
        t_header,
        t_footer,
    },
	props: {
		msg: String,
	},
	data() {
    let userInfo = localStorage.getItem('setuserInfo')
    if(userInfo && userInfo != 'null'){
      userInfo = JSON.parse(userInfo)
    }else{
      userInfo = {}
    }
		return {
			isSort:false,
			path: "/myself",
			userInfo,
			isTecherType: 0,
			isPartnerType: 0,
			arr:['2'],
			isUserInfo:false,
			showTitle:''
		};
	},
	components: {
		t_header,
		t_footer
	},
	mounted() {
		this.path = this.$store.state.path;
		this.isTecherType = this.$store.state.isTecherType;
		this.isPartnerType = this.$store.state.isPartnerType;
		this.showTitle = oTit[this.path]
	},
	watch: {
		$route(to, from) {
			this.path = this.$store.state.path;
			this.showTitle = oTit[this.path]
		}
	},
	methods: {
		// 父传子
        getTodos(e){
        
            this.isUserInfo=e
        },
		// 退出
		signOut() {
			// this.$store.removeItem("setuserInfo")
			localStorage.removeItem("token");
			localStorage.removeItem("setuserInfo");
			this.islogin = false
			this.$router.push({
				path: '/',
				query: {}
			});
		},
		// 跳转页面
		topage(page) {
			// var obj = {};
			// obj.key = page;
			// obj.value = "";
			// if(page == '/myself/myCenter'){
			// 	this.showTitle = '学习概况'
			// }
			this.$router.push({
				path: page,
				query: {},
			});
		},
		// 获取用户信息
		// getUserInfo() {
		// 	get_user_info().then((res) => {
		// 		console.log(res);
		// 		if (res.data.code == 0) {
		// 			this.$store.commit("setuserInfo", { userInfo: res.data.result });
		// 			this.userInfo = res.data.result;
		// 		} else {
		// 			this.$message.error(res.data.msg);
		// 			this.$router.go(-1);
		// 		}
		// 	}).catch((error) => {
		// 		console.log(error);
		// 	});
		// },
		handleClose(){

		},
		handleOpen(){
			
		}
	},
};
</script>
<style>
.el-submenu .el-menu-item{
  height:40px;
  line-height: 40px;
}
.el-menu-item-group__title{
	padding: 0 0 0 20px !important;
}
</style>